export function debounce<Params extends any[]>(timeout: number, func: (...args: Params) => any) {
	let timer: NodeJS.Timeout;
	return (...args: Params) => {
		if (timer) clearTimeout(timer);
		timer = setTimeout(() => func(...args), timeout);
	};
}

// Throttling Function
export function throttle<Params extends any[]>(
	func: (...args: Params) => any,
	delayInMilliSeconds: number
) {
	// Previously called time of the function
	let prev = 0;
	return (...args: Params) => {
		// Current called time of the function
		const now = new Date().getTime();

		// If difference is greater
		// than delay call
		// the function again.
		if (now - prev > delayInMilliSeconds) {
			prev = now;

			// "..." is the spread
			// operator here
			// returning the function with the
			// array of arguments
			return func(...args);
		}
	};
}
